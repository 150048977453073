/**
 * 使用权限码判断的hook
 * const isAuth = useAuth(String)
 * params: String | Number = "1101" || "1101,1103" || 1105
 * 注：当权限码为数字0开头，请用字符串代替
 */

export const useAuth = (code) => {
  if (!code) return true; // 传入的值为false,默认不校验直接返回true
  const RULES = localStorage.getItem("RULES");
  // console.log(RULES, "RULES");
  // console.log("typeof RULES", typeof RULES, RULES);
  const Auth = new Set(typeof RULES === "string" ? JSON.parse(RULES) : []);
  const Codes = code.toString().split(",");
  let count = 0;
  for (let index = 0; index < Codes.length; index++) {
    const Code = Codes[index];
    if (Auth.has(Code)) count++;
  }
  return Codes.length === count;
};
