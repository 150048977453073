<template>
  <!-- 未认证提示 -->
  <div
    v-if="useAuth(auth) && mchCertStatus.status !== 1"
    class="u-m-12 no-cert-tips u-flex u-row-between u-radius-6"
  >
    <van-image width="72" height="70" :src="NoCertImg" />
    <div class="u-flex-1 u-m-l-8 u-m-r-3">
      <p class="u-font-22 u-line-height-30">完成实名认证</p>
      <p class="u-font-13 u-line-height-20">
        {{ CertTipsText[mchCertStatus.status].tips }}
      </p>
    </div>
    <div
      class="go-cert bg-white"
      @click="handleClickMenu({ key: 'vertifi', path: '/user/certification' })"
    >
      <span class="u-theme-color cert-text u-m-r-6">
        {{ CertTipsText[mchCertStatus.status].btn }}
      </span>
      <van-icon class-prefix="iconfont" name="you" color="#027AFF" size="16" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useState } from "@/hooks/useVuex";
import { useRouter } from "vue-router";
const CertTipsText = [
  {
    tips: "审核通过后，才能正常收款",
    btn: "去认证",
  },
  {
    tips: "",
    btn: "",
  },
  {
    tips: "认证已提交，待审核",
    btn: "查看",
  },
  {
    tips: "认证已驳回，请及时修改",
    btn: "去认证",
  },
  {
    tips: "确认开户意愿",
    btn: "去确认",
  },
  {
    tips: "待平台审核",
    btn: "查看",
  },
];
export default defineComponent({
  name: "CertTips",
  inject: ["useAuth"],
  props: ["auth"],
  setup() {
    const router = useRouter();
    const { mchCertStatus } = useState("app", ["mchCertStatus"]);
    const handleClickMenu = (menu) => {
      console.log(menu);
      if (menu.key === "vertifi" && mchCertStatus.value.status) {
        router.push({
          path: "/user/certification/status",
          query: {
            status: mchCertStatus.value.status,
            rejectReason: mchCertStatus.value.rejectReason,
          },
        });
        return false;
      }
      if (menu.path) {
        router.push({
          path: menu.path,
        });
      }
    };
    return {
      NoCertImg: require("@/assets/images/ic_no_cert.png"),
      CertTipsText,
      mchCertStatus,
      handleClickMenu,
    };
  },
});
</script>

<style lang="scss" scoped>
.no-cert-tips {
  color: #ffffff;
  padding: 6px 12px;
  background: linear-gradient(180deg, #5c94f1 0%, #027aff 100%);
  .go-cert {
    width: 78px;
    height: 26px;
    border-radius: 15px;
    text-align: center;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    line-height: normal;
    justify-content: center;
    .cert-text {
      font-size: 14px;
    }
  }
}
</style>
