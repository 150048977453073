import {
  addPackageTmp,
  deleteTmpItem,
  insertTmpItem,
  packageTmpDetail,
  packageTmpList,
  templateSetSit,
  updatePackageTmp,
  updateRecommend,
  updateTmpItem,
  updateTmpStatus,
} from "@/api/package";
import { useAuth } from "@/hooks/useAuth";
import { Toast } from "vant";
export default {
  namespaced: true,
  state: {
    templateList: [],
    templateDetail: {},
  },
  mutations: {
    SET_TEMPLATELIST(state, payload) {
      state.templateList = [...payload];
    },
    SET_TEMPLATE_DETAIL(state, payload) {
      state.templateDetail = { ...payload };
    },
  },
  actions: {
    async getTemplateList({ commit }, payload) {
      if (!useAuth("B030101")) {
        Toast("暂无权限");
        return false;
      }
      try {
        const { code, content } = await packageTmpList({
          templateName: payload || "",
        });
        if (code === 200) {
          commit("SET_TEMPLATELIST", content);
        }
        console.log(commit);
      } catch (error) {
        console.log(error);
      }
    },
    async insertTemplateItem({ commit }, payload) {
      let url = updatePackageTmp;
      if (!payload.templateId) {
        delete payload.templateId;
        url = addPackageTmp;
      }
      const { code, content } = await url({ ...payload });
      console.log(commit);
      return { code, content };
    },
    async getTemplateDetail({ commit }, payload) {
      const { code, content } = await packageTmpDetail({
        templateId: payload,
      });
      if (code === 200) {
        commit("SET_TEMPLATE_DETAIL", content);
        return;
      }
    },
    // 删除一条套餐模板明细
    async deleteTmpItem(ctx, payload) {
      return await deleteTmpItem(payload);
    },
    // 修改一条套餐模板明细
    async updateTmpItemActions(ctx, payload) {
      let url = insertTmpItem;
      if (payload.templateItemId) {
        url = updateTmpItem;
        // 编辑不需要这么多参数，也不知道之前为什么传多了接口不报错
        payload = {
          templateItemId: payload.templateItemId,
          amount: payload.amount,
          virtualValue: payload.virtualValue,
          itemDesc: payload.itemDesc,
        };
      }
      return await url(payload);
    },
    // 启用禁用模板
    async updateTmpStatusActions({ commit }, payload) {
      console.log(commit, payload, "payload");
      return await updateTmpStatus({ ...payload });
    },
    async updateRecommend({ commit }, payload) {
      console.log(commit, payload);
      return await updateRecommend(payload);
    },
    async setTemplateSit({ commit }, payload) {
      console.log(commit);
      const { code } = await templateSetSit({ ...payload });
      return code;
    },
  },
  getters: {},
};
