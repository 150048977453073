import { LocalStorageEnum } from "@/hooks/useLocalStorage";
export const getCurrentRole = () => {
  return localStorage.getItem(LocalStorageEnum.ROLE);
};

export const isOperator = () => {
  return getCurrentRole() === "Operator";
};

export const isVendor = () => {
  return getCurrentRole() === "Vendor";
};
