import { getJwtAppId } from "@/hooks/useLocalStorage";
import request from "@/request/interceptors";

// 获取首页看板数据
export function getDashboardDataApi() {
  return request({
    url: "mch/data/statistics/v1/dashboard",
    method: "get",
  });
}

// 注册
export function mchUserRegisterApi(data) {
  return request({
    url: "mch/user/auth/v1/register",
    method: "post",
    data,
  });
}

// 登录
export function mchUserLoginApi(data) {
  return request({
    url: "mch/user/auth/v1/login",
    method: "post",
    data,
  });
}

// 根据用户名查询手机号
export function getUserPhoneByNameApi(params) {
  return request({
    url: "mch/user/userInfo/v1/phone",
    method: "get",
    params,
  });
}

// 修改密码
export function mchUpdatePasswordApi(data) {
  return request({
    url: "mch/user/userInfo/v1/password",
    method: "post",
    data,
  });
}

// 忘记找回密码
export function mchForgetPasswordApi(data) {
  return request({
    url: "mch/user/userInfo/v1/password/forget",
    method: "post",
    data,
  });
}

// 查询用户授权信息
export function getUserAuthInfoApi(params) {
  return request({
    url: "/mch/user/auth/v1/auth",
    method: "get",
    params,
  });
}

// 查询用户详情
export function getUserInfoDetailApi() {
  return request({
    url: "mch/user/auth/v1/detail",
    method: "post",
  });
}

// 退出登录
export function logoutApi() {
  return request({
    url: "mch/user/auth/v1/logOut",
    method: "get",
  });
}

// 查询商户认证状态
export function getMchCertStatusApi() {
  return request({
    url: "mch/user/mchCert/v1/state",
    method: "get",
  });
}

// 新增实名认证
export function mchCertAddApi(data) {
  return request({
    url: "mch/user/mchCert/v1/insert",
    method: "post",
    data,
  });
}

// 重新实名认证
export function mchCertUpdateApi(data) {
  return request({
    url: "mch/user/mchCert/v1/reCert",
    method: "post",
    data,
  });
}

// 查询认证详情
export function getMchCertDetailApi() {
  return request({
    url: "mch/user/mchCert/v1/detail",
    method: "get",
  });
}

// 确定微信已审核通过
export function confirmPassByWeixinApi(params) {
  return request({
    url: "mch/user/mchCert/v1/state/wechat",
    method: "get",
    params,
  });
}

// 根据名称查询银行信息
export function getBankListByNameApi(params) {
  return request({
    url: "mch/user/mchCert/v1/list/bank",
    method: "get",
    params,
  });
}

// 查询网点信息
export function getBankBranchListApi(params) {
  return request({
    url: "mch/user/mchCert/v1/list/branch",
    method: "get",
    params,
  });
}

// 子账号列表
export function getSubAccountListBySiteApi(data) {
  return request({
    url: "mch/user/userInfo/v1/list/site",
    method: "post",
    data,
  });
}

// 获取角色列表
export function getUserRoleListApi(params) {
  return request({
    url: "mch/user/rabc/userRole/v1/list",
    method: "get",
    params,
  });
}

// 新增子账号
export function addUserSubAccountApi(data) {
  return request({
    url: "mch/user/userInfo/v1/insert",
    method: "post",
    data,
  });
}

// 修改子账号
export function updateUserSubAccountApi(data) {
  return request({
    url: "mch/user/userInfo/v1/update",
    method: "post",
    data,
  });
}

// 禁用启用用户
export function changeAccountStatusApi(params) {
  return request({
    url: "mch/user/userInfo/v1/status",
    method: "get",
    params,
  });
}

// 获取用户详情信息
export function getSubUserInfoApi(params) {
  return request({
    url: "mch/user/userInfo/v1/detail",
    method: "get",
    params,
  });
}

/** -------------------------------分割线----------------------------------  */

// 获取openId
export function getWeixinOpenIdApi(data) {
  return request({
    url: "mch/base/thirdauth/v1/white",
    method: "post",
    data: {
      // 固定参数
      jwtAppId: getJwtAppId(),
      thirdPlatform: "wechat",
      thirdPlatformSource: 1,
      code: data.code,
    },
  });
}

// 选择一个账号登录
export function loginByUserIdApi(data) {
  return request({
    url: "mch/user/auth/thirdapp/v1/thirdPlatform/auth",
    method: "post",
    data: {
      ...data,
    },
  });
}

// 登录检查绑定列表
export function getWxBindListApi(data) {
  return request({
    url: "mch/user/auth/thirdapp/v1/check/bind",
    method: "post",
    data: {
      ...data,
    },
  });
}

// 通过userId检查是否绑定
export function getWxBindListByUserIdApi(data) {
  return request({
    url: "mch/user/auth/thirdapp/v1/check/bind/userId",
    method: "post",
    data: {
      ...data,
    },
  });
}

// 绑定微信
export function bindWeixinApi(data) {
  return request({
    url: "mch/user/auth/thirdapp/v1/bind/user",
    method: "post",
    data: {
      ...data,
    },
  });
}

// 校验登录
export function verifyLoginApi(data) {
  return request({
    url: "mch/user/auth/thirdapp/v1/login/verify",
    method: "post",
    data: {
      ...data,
    },
  });
}

// 解除绑定
export function unbindApi(data) {
  return request({
    url: "mch/user/auth/thirdapp/v1/unbind/user",
    method: "post",
    data,
  });
}
