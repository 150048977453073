export default [
  {
    path: "/advertisement/list",
    name: "AdvertisementList",
    meta: {
      title: "广告管理",
      check: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "advertisement" */ "@/views/advertisement/index.vue"
      ),
  },
  {
    path: "/advertisement/detail",
    name: "AdvertisementDetail",
    meta: {
      title: "新建广告",
      check: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "advertisement" */ "@/views/advertisement/detail.vue"
      ),
  },
  {
    path: "/advertisement/push",
    name: "AdvertisementPush",
    meta: {
      title: "推送广告",
      check: true,
      noAlive: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "advertisement" */ "@/views/advertisement/push.vue"
      ),
  },
  {
    path: "/advertisement/device",
    name: "DeviceAdvertisementList",
    meta: {
      title: "机器广告",
      check: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "advertisement" */ "@/views/advertisement/deviceAd.vue"
      ),
  },
];
