<template>
  <!-- 数据看板 -->
  <div class="dashboard">
    <div class="data-total u-text-center u-m-t-20">
      <span class="u-font-32">{{ amountFmt(dashboard.totalAmount) }}</span>
      <p class="data-labal">今日收款(元)</p>
    </div>
    <div class="data-device u-flex u-m-t-20 u-m-b-20">
      <div class="device u-text-center">
        <span>{{ dashboard.onLineDevice || 0 }}</span>
        <p class="data-labal">在线设备(台)</p>
      </div>

      <div class="device u-text-center">
        <span>{{ dashboard.coinNum || 0 }}</span>
        <p class="data-labal">投币总数(个)</p>
      </div>
    </div>
  </div>
  <!-- 菜单 -->
  <div v-if="TopMenusList.length" class="top-menu bg-white u-flex">
    <div
      v-for="menu in TopMenusList"
      :key="menu.path"
      class="menu-item u-flex u-flex-column"
      @click="handleClickMenu(menu)"
    >
      <div
        class="iconfont-box u-flex u-row-center"
        :style="{ background: menu.bg }"
      >
        <i class="iconfont" :class="[menu.icon]" />
      </div>
      <span class="u-m-t-8">{{ menu.name }}</span>
    </div>
  </div>
  <!-- 功能菜单 -->
  <div v-if="BottomMenusList.length" class="u-m-t-12 bg-white">
    <p class="u-p-t-12 u-p-b-18 u-p-l-14">功能</p>
    <div class="u-flex u-flex-wrap">
      <div
        v-for="menu in BottomMenusList"
        :key="menu.path"
        class="menu-item u-m-b-18 u-flex u-flex-column"
        @click="handleClickMenu(menu)"
      >
        <i
          class="iconfont"
          :class="[menu.icon]"
          :style="{ color: menu.bg, fontSize: '32px' }"
        />
        <span class="u-m-t-8 u-content-color u-font-14">{{ menu.name }}</span>
      </div>
    </div>
  </div>
  <!-- 认证tips -->
  <CertTips auth="B0403" />
  <!-- 联系客服弹窗 -->
  <ConcactUs v-model:visible="isShowConcatUsPop" />
</template>

<script>
import { defineComponent, ref, onActivated, inject } from "vue";
import { useRouter } from "vue-router";
import { TopMenus, BottomMenus } from "../const";
import { getDashboardDataApi } from "@/api/user";
import { amountFmt } from "@/utils/format";
import { useState } from "@/hooks/useVuex";
import ConcactUs from "@/components/ConcactUs.vue";
import CertTips from "./CertTips.vue";

export default defineComponent({
  name: "Operator",
  components: {
    ConcactUs,
    CertTips,
  },
  inject: ["useAuth"],
  setup() {
    const router = useRouter();
    const dashboard = ref({});
    const TopMenusList = ref([]);
    const BottomMenusList = ref([]);
    const isShowConcatUsPop = ref(false);
    const useAuth = inject("useAuth");
    const { mchCertStatus } = useState("app", ["mchCertStatus"]);
    onActivated(() => {
      console.log("onActivated----------------Operator----------------111");
      TopMenusList.value = TopMenus.filter((m) => {
        return useAuth(m.auth);
      });
      BottomMenusList.value = BottomMenus.filter((m) => {
        return useAuth(m.auth);
      });
      console.log(
        "onActivated----------------TopMenusList----------------TopMenus",
        TopMenus,
        TopMenusList.value
      );
      getDashboardData();
    });
    const getDashboardData = async () => {
      try {
        const { code, content } = await getDashboardDataApi();
        if (code === 200) {
          console.log("content", content);
          dashboard.value = content;
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    const handleClickMenu = (menu) => {
      console.log(menu);
      // 实名认证
      if (menu.key === "vertifi" && mchCertStatus.value.status) {
        router.push({
          path: "/user/certification/status",
          query: {
            status: mchCertStatus.value.status,
            rejectReason: mchCertStatus.value.rejectReason,
          },
        });
        return false;
      }
      // 联系客服
      if (menu.key === "concact") {
        isShowConcatUsPop.value = true;
        return false;
      }
      if (menu.path) {
        router.push({
          path: menu.path,
        });
      }
      if (menu.to) {
        location.href = menu.to;
      }
    };
    return {
      isShowConcatUsPop,
      TopMenusList,
      BottomMenusList,
      dashboard,
      amountFmt,
      handleClickMenu,
    };
  },
});
</script>

<style lang="scss" scoped>
.dashboard {
  background-image: url("../../../assets/images/home_bg.png");
  min-height: 160px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #ffffff;
  overflow: hidden;
  position: relative;
  .device {
    width: 50%;
    flex: 1;
  }
  .data-labal {
    font-size: 14px;
    margin-top: 12px;
    text-align: center;
  }
}
.menu-item {
  width: 25%;
}
.top-menu {
  padding: 12px 0 16px 0;
  .iconfont-box {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    i {
      color: #ffffff;
      font-size: 24px;
    }
  }
}
</style>
