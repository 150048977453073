import request from "@/request/interceptors";

// 检查imei是否绑定
export function checkDeviceImeiBindApi(params) {
  return request({
    url: "mch/device/register/v1/check/imei",
    method: "get",
    params,
  });
}

// 检查支付码是否绑定
export function checkDeviceQrcodeBindApi(params) {
  return request({
    url: "mch/device/register/v1/check/qrcode",
    method: "get",
    params,
  });
}
// 商户设备注册激活
export function deviceRegisterApi(data) {
  return request({
    url: "mch/device/register/v1",
    method: "post",
    data,
  });
}

// 获取运营商设备列表
export function getMchDeviceListApi(params) {
  return request({
    url: "mch/device/info/v1/page",
    method: "get",
    params,
  });
}

// 获取设备详情接口
export function getMchDeviceDetailApi(params) {
  return request({
    url: "mch/device/info/v1/detail",
    method: "get",
    params,
  });
}

// 更新设备启动单价
export function updateDeviceStartCoinApi(data) {
  return request({
    url: "mch/device/info/v1/workCoin/update",
    method: "post",
    data,
  });
}

// 解除设备绑定
export function unBindMchDeviceApi(data) {
  return request({
    url: "mch/device/register/v1/unbind",
    method: "post",
    data,
  });
}

// 更新设备基础信息
export function updateDeviceInfoApi(data) {
  return request({
    url: "mch/device/info/v1/info/update",
    method: "post",
    data,
  });
}

// 根据设备ID查询设备当前关联礼品
export function getDeviceGiftApi(params) {
  return request({
    url: "mch/device/goods/v1/get",
    method: "get",
    params,
  });
}

// 礼品设置
export function setDeviceGiftApi(data) {
  return request({
    url: "mch/device/goods/v1/set",
    method: "post",
    data,
  });
}

// 硬件参数设置
export function getMchDeviceConfigListApi(params) {
  return request({
    url: "mch/device/config/v1/hardware/list",
    method: "get",
    params,
  });
}

// 更新硬件参数设置
export function updateMchDeviceConfigListApi(data) {
  return request({
    url: "mch/device/config/v1/hardware/update",
    method: "post",
    data,
  });
}

// 启动测试
export function testMchDeviceShipmentApi(data) {
  return request({
    url: "mch/device/info/v1/test/shipment",
    method: "post",
    data,
  });
}

// 获取场地设备序号
export function getSiteSortNoApi(params) {
  return request({
    url: "mch/device/site/v1/sortNo/list",
    method: "get",
    params,
  });
}

// 获取场地列表
export function getSiteListApi(params) {
  return request({
    url: "mch/user/mchInfo/mchSite/v1/list/user",
    method: "get",
    params,
  });
}

// 获取商品列表
export function getGoodsListApi(params) {
  return request({
    url: "mch/goods/info/v1/list",
    method: "get",
    params,
  });
}

// 获取套餐列表
export function getPackageListApi(params) {
  return request({
    url: "mch/user/marketing/template/v1/list/templateName",
    method: "get",
    params,
  });
}

// 设置营业状态
export function updateDeviceStateApi(data) {
  return request({
    url: "mch/device/info/v1/update/state",
    method: "post",
    data,
  });
}
// 设置自动投币
export function updateDeviceSoftApi(data) {
  return request({
    url: "mch/device/config/v1/soft/update",
    method: "post",
    data,
  });
}
