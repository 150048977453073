<template>
  <!-- 流程指南 -->
  <div
    class="u-m-12 no-cert-tips u-flex u-row-between u-radius-6"
    @click="handleToPath"
  >
    <van-image width="72" height="70" :src="NoCertImg" />
    <div class="u-flex-1 u-m-l-8 u-m-r-3">
      <p class="u-font-22 u-line-height-30">天鹿智能厂商指南</p>
      <p class="u-flex u-font-13 u-line-height-20">
        <span class="u-m-r-6">软硬件接入，快速入门</span>
        <van-icon class="rotate-column" name="down" size="20" color="#ffffff" />
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Guide",
  props: ["href"],
  setup(props) {
    const handleToPath = () => {
      location.href = props.href;
    };
    return {
      NoCertImg: require("@/assets/images/ic_no_cert.png"),
      handleToPath,
    };
  },
});
</script>

<style lang="scss" scoped>
.no-cert-tips {
  color: #ffffff;
  padding: 6px 12px;
  background: linear-gradient(180deg, #5c94f1 0%, #027aff 100%);
}
.rotate-column {
  transform: rotate(-90deg);
}
</style>
