export default [
  {
    path: "/goods/list",
    name: "GoodsList",
    meta: {
      title: "商品管理",
      check: true,
    },
    component: () =>
      import(/* webpackChunkName: "other" */ "@/views/goods/index.vue"),
  },
  {
    path: "/goods/detail",
    name: "GoodsDetail",
    meta: {
      title: "商品详情",
      check: true,
    },
    component: () =>
      import(/* webpackChunkName: "other" */ "@/views/goods/details.vue"),
  },
  {
    path: "/order/list",
    name: "OrderList",
    meta: {
      title: "订单管理",
      check: true,
    },
    component: () =>
      import(/* webpackChunkName: "other" */ "@/views/order/index.vue"),
  },
  {
    path: "/order/detail",
    name: "OrderDetail",
    meta: {
      title: "订单详情",
      check: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "other" */ "@/views/order/detail/order-detail.vue"
      ),
  },
  {
    path: "/package/list",
    name: "PackageIndex",
    meta: {
      title: "商品套餐",
      check: true,
    },
    component: () =>
      import(/* webpackChunkName: "other" */ "@/views/package/index.vue"),
  },
  {
    path: "/package/template",
    name: "PackageTemplate",
    meta: {
      title: "套餐模板",
      check: true,
    },
    component: () =>
      import(/* webpackChunkName: "other" */ "@/views/package/template.vue"),
  },
  {
    path: "/package/edit",
    name: "PackageEdit",
    meta: {
      title: "套餐管理",
      check: true,
    },
    component: () =>
      import(/* webpackChunkName: "other" */ "@/views/package/edit.vue"),
  },
  {
    path: "/package/detail",
    name: "PackageDetail",
    meta: {
      title: "套餐管理",
      check: true,
    },
    component: () =>
      import(/* webpackChunkName: "other" */ "@/views/package/detail.vue"),
  },
  {
    path: "/notice/index",
    name: "NoticeSetting",
    meta: {
      title: "通知策略",
      check: true,
    },
    component: () =>
      import(/* webpackChunkName: "other" */ "@/views/notice/index.vue"),
  },
  {
    path: "/notice/config",
    name: "NoticeConfig",
    meta: {
      title: "通知配置",
      check: true,
    },
    component: () =>
      import(/* webpackChunkName: "other" */ "@/views/notice/config.vue"),
  },
];
