import { getMchDeviceDetailApi } from "@/api/device";
import { factoryGetDeviceDetailApi } from "@/api/vendor";
import { isOperator } from "@/hooks/useRole";
export default {
  namespaced: true,
  state: {
    deviceDetail: {},
  },
  mutations: {
    SET_DEVICE_DETAIL(state, payload) {
      state.deviceDetail = {
        ...payload,
        // isSell: 0,
        // networkSignal: 0,
        // onlineState: 1,
      };
    },
  },
  actions: {
    async getDeviceDetailAction({ commit }, payload) {
      try {
        const actions = isOperator()
          ? getMchDeviceDetailApi
          : factoryGetDeviceDetailApi;
        const { code, content } = await actions({
          deviceId: payload.deviceId,
        });
        if (code === 200) {
          commit("SET_DEVICE_DETAIL", content || {});
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  },
  getters: {
    deviceDetail(state) {
      return state.deviceDetail;
    },
  },
};
