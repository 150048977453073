export default [
  {
    path: "/member/list",
    name: "MemberList",
    meta: {
      title: "会员管理",
      check: true,
    },
    component: () =>
      import(/* webpackChunkName: "member" */ "@/views/member/index.vue"),
  },
  {
    path: "/member/info",
    meta: {
      title: "会员详情",
      check: true,
    },
    redirect: "/member/info/coinList",
    component: () =>
      import(
        /* webpackChunkName: "member" */ "@/views/member/detail/index.vue"
      ),
    children: [
      {
        path: "coinList",
        name: "CoinList",
        component: () =>
          import(
            /* webpackChunkName: "member" */ "@/views/member/detail/coinList.vue"
          ),
      },
      {
        path: "coinRecord",
        name: "CoinRecord",
        component: () =>
          import(
            /* webpackChunkName: "member" */ "@/views/member/detail/coinRecord.vue"
          ),
      },
    ],
  },
];
