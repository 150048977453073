<template>
  <layout />
</template>
<script>
import layout from "@/layouts/index.vue";
import { defineComponent, provide } from "vue";
export default defineComponent({
  components: {
    layout,
  },
  setup() {
    provide("ImagePrefix", "https://store.lerke.cn/");
  },
});
</script>
<style lang="scss">
#app {
  font-family: PingFangSC-Regular, PingFang SC, Avenir, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
