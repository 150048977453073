import { createStore } from "vuex";
import app from "./modules/app";
import device from "./modules/device";
import goods from "./modules/goods";
import income from "./modules/income";
import order from "./modules/order";
import goodsPackage from "./modules/package";
import user from "./modules/user";
export default createStore({
  modules: {
    app,
    device,
    goodsPackage,
    goods,
    order,
    income,
    user,
  },
});
