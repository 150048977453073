import { getAreaListApi } from "@/api/app";
import { getMchCertStatusApi } from "@/api/user";
import { factoryCheckCertStatusApi } from "@/api/vendor";
import { useAuth } from "@/hooks/useAuth";
import { isOperator } from "@/hooks/useRole";
import { getWxJsConfigAction } from "@/utils/wx-config";
import { Toast } from "vant";
export default {
  namespaced: true,
  state: {
    areaList: [],
    mchCertStatus: {
      status: 1,
      rejectReason: "",
    }, // 商户实名认证状态 0.未认证 1. 审核通过 2.待审核 3.驳回
  },
  mutations: {
    SET_AREA_LIST(state, payload) {
      state.areaList = payload;
    },
    SET_MCH_CERT_STATUS(state, payload) {
      state.mchCertStatus = payload;
    },
  },
  actions: {
    // 退出登录操作
    // resetToken() {
    //   // 需要清空的本地缓存
    //   const StorageKeys = ["CAS_TOKEN", "AUTH", "RULES", "USER", "TOKEN_EXP"];
    //   StorageKeys.forEach((key) => {
    //     localStorage.removeItem(key);
    //   });
    //   console.log("清除缓存成功!");
    //   router.replace({
    //     path: "/login",
    //   });
    // },
    // 登录完之后的操作
    // async afterLoginAction({ dispatch }, payload) {
    //   const { accessToken, exp } = payload;
    //   localStorage.setItem("casToken", accessToken);
    //   localStorage.setItem("TokenExp", exp);
    //   // 获取用户信息详情
    //   console.log("111", 111);
    //   await dispatch("getUserAuthDetail");
    //   console.log("333", 333);
    //   dispatch("getAreaListAction");
    //   console.log("444", 444);
    //   router.replace({
    //     path: "/home/index",
    //   });
    // },
    // 扫码
    async handleScanCode(ctx, callback) {
      if (process.env.VUE_APP_ENV === "development") {
        return Promise.resolve(callback("G3630736EE2F066C91BAB09412"));
        // const resultStr = JSON.stringify({
        //   sn: "002001420020b6682137",
        //   platformType: "ANDROID",
        //   packageName: "com.lerke.amusement",
        //   imei: "863958043042220",
        //   androidId: "5a6adedb5358f4eb",
        // });
        // return Promise.resolve(callback(resultStr));
      }
      await getWxJsConfigAction();
      return new Promise((resolve, reject) => {
        window.wx.ready(() => {
          window.wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            success: (res) => {
              console.log(res.resultStr);
              resolve(callback(res.resultStr));
            },
            fail: (e) => {
              Toast("二维码扫描失败!");
              console.log("e", e);
              reject(e);
            },
          });
        });
      });
    },
    // 查询商户认证状态
    async getCertStatusAction({ commit }, payload = {}) {
      if (
        (isOperator() && !useAuth("B0403")) ||
        (!isOperator() && !useAuth("C0201"))
      )
        return false;
      const checkAction = isOperator()
        ? getMchCertStatusApi
        : factoryCheckCertStatusApi;
      try {
        const { code, content } = await checkAction({
          ...payload,
        });
        if (code === 200) {
          console.log("content", content);
          commit(
            "SET_MCH_CERT_STATUS",
            content || {
              status: 0,
              rejectReason: "",
            }
          );
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    // 获取省市区接口
    async getAreaListAction({ commit }) {
      try {
        const {
          code,
          content: { children },
        } = await getAreaListApi();
        if (code === 200) {
          children.forEach((p) => {
            if (["710000", "810000", "820000"].includes(p.id)) {
              p.children.push({
                name: "",
                id: "",
                children: [],
              });
            }
          });
          commit("SET_AREA_LIST", children);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  },
  getters: {},
};
