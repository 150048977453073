export default [
  {
    path: "/record/income",
    meta: {
      title: "收入汇总",
      check: true,
    },
    redirect: "/record/income/sales",
    component: () =>
      import(
        /* webpackChunkName: "income" */ "@/views/records/income/index.vue"
      ),
    children: [
      {
        path: "sales",
        name: "IncomeSales",
        component: () =>
          import(
            /* webpackChunkName: "income" */ "@/views/records/income/sales.vue"
          ),
      },
      {
        path: "site",
        name: "IncomeSite",
        component: () =>
          import(
            /* webpackChunkName: "income" */ "@/views/records/income/site.vue"
          ),
      },
      {
        path: "device",
        name: "IncomeDevice",
        component: () =>
          import(
            /* webpackChunkName: "income" */ "@/views/records/income/device.vue"
          ),
      },
    ],
  },
  {
    path: "/record/income/detail",
    meta: {
      title: "销售详情",
      check: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "income" */ "@/views/records/income/detail.vue"
      ),
  },
];
