export const getQueryStringByUrl = function () {
  // http://localhost:9191/redirect?r=1 // 运营商
  // http://localhost:9191/redirect?r=2 // 厂商
  let query = {};
  let href = location.href;
  console.log("=====herf======", href);
  let urls = href.split("#"); // hash
  // console.log(urls, "urls");
  urls.forEach((url) => {
    let querystr = url.split("?")[1];
    if (!querystr) return;
    let querys = querystr.split("&") || [];
    querys.forEach((_q) => {
      try {
        let keys = _q.split("=");
        query[keys[0]] = decodeURIComponent(keys[1]);
      } catch (error) {
        console.log("error");
      }
    });
  });
  console.log("query==========", query);
  return new Promise((resolve, reject) => {
    if (query) {
      resolve(query);
    } else {
      reject(false);
    }
  });
};
