import request from "@/request/interceptors";

// 获取收入汇总-销售额
export function getIncomeSalesApi(params) {
  return request({
    url: "mch/data/statistics/v1/summary",
    method: "GET",
    params,
  });
}

// 获取收入汇总-场地
export function getIncomeSiteApi(data) {
  return request({
    url: "mch/data/statistics/v1/site/list",
    method: "POST",
    data,
  });
}

// 获取收入汇总-设备
export function getIncomeDeviceApi(data) {
  return request({
    url: "mch/data/statistics/v1/device/list/page",
    method: "POST",
    data,
  });
}

// 获取收入汇总-场地详情
export function getIncomeSiteDetailApi(params) {
  return request({
    url: "mch/data/statistics/v1/site/detail",
    method: "GET",
    params,
  });
}

// 获取收入汇总-设备销售额详情
export function getIncomeDeviceDetailApi(params) {
  return request({
    url: "mch/data/statistics/v1/device/detail",
    method: "GET",
    params,
  });
}
