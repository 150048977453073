export default [
  {
    path: "/refund/list",
    name: "RefundRecordList",
    meta: {
      title: "退款记录",
      check: true,
    },
    component: () =>
      import(/* webpackChunkName: "refund" */ "@/views/refund/index.vue"),
  },
  {
    path: "/refund/detail",
    name: "RefundOrderDetail",
    meta: {
      title: "退款信息",
      check: true,
    },
    component: () =>
      import(/* webpackChunkName: "refund" */ "@/views/refund/detail.vue"),
  },
  {
    path: "/refund/add",
    name: "RefundAdd",
    meta: {
      title: "商品退款",
      check: true,
    },
    component: () =>
      import(/* webpackChunkName: "refund" */ "@/views/refund/add.vue"),
  },
];
