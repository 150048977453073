/**
 * 使用mapState和mapGetters的hook
 * const storeState = useState(modulesName, [])
 */

import { computed } from "vue";
import {
  createNamespacedHelpers,
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
  useStore,
} from "vuex";

const useMapper = (mapper, mapFn, isFn = false) => {
  const store = useStore();

  const storeFns = mapFn(mapper);
  const Store = {};
  Object.keys(storeFns).forEach((keyFn) => {
    const fn = storeFns[keyFn].bind({ $store: store });
    Store[keyFn] = isFn ? fn : computed(fn);
  });

  return Store;
};
// 获取state
export const useState = (moduleName, mapper) => {
  let mapperFn = mapState;
  if (typeof moduleName === "string" && moduleName.length > 0) {
    mapperFn = createNamespacedHelpers(moduleName).mapState;
  } else {
    mapper = moduleName;
  }
  return useMapper(mapper, mapperFn);
};
// 获取getters
export const useGetters = (moduleName, mapper) => {
  let mapperFn = mapGetters;
  if (typeof moduleName === "string" && moduleName.length > 0) {
    mapperFn = createNamespacedHelpers(moduleName).mapGetters;
  } else {
    mapper = moduleName;
  }
  return useMapper(mapper, mapperFn);
};

// 获取actions
export const useActions = (moduleName, mapper) => {
  let mapperFn = mapActions;
  if (typeof moduleName === "string" && moduleName.length > 0) {
    mapperFn = createNamespacedHelpers(moduleName).mapActions;
  } else {
    mapper = moduleName;
  }
  return useMapper(mapper, mapperFn, true);
};

// 获取mutations
export const useMutations = (moduleName, mapper) => {
  let mapperFn = mapMutations;
  if (typeof moduleName === "string" && moduleName.length > 0) {
    mapperFn = createNamespacedHelpers(moduleName).mapMutations;
  } else {
    mapper = moduleName;
  }
  return useMapper(mapper, mapperFn, true);
};
