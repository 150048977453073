/* eslint-disable */
// import "@/assets/css/nutui.scss";
import "@/assets/css/global.scss";
import Page from "@/components/Page.vue";
import { useAuth } from "@/hooks/useAuth";
import { getCasToken, LocalStorageEnum } from "@/hooks/useLocalStorage";
import "lib-flexible";
import {
  ActionSheet,
  Area,
  Button,
  Cell,
  CellGroup,
  Checkbox,
  CheckboxGroup,
  Col,
  Collapse,
  CollapseItem,
  ConfigProvider,
  DatetimePicker,
  Dialog,
  Empty,
  Field,
  Form,
  Icon,
  Image as VanImage,
  List,
  Loading,
  NoticeBar,
  Overlay,
  Picker,
  Popup,
  PullRefresh,
  Radio,
  RadioGroup,
  Row,
  Search,
  Skeleton,
  Step,
  Stepper,
  Steps,
  Sticky,
  Switch,
  Tab,
  Tabbar,
  TabbarItem,
  Tabs,
  Uploader
} from "vant";
import VConsole from "vconsole";
import { createApp } from "vue";
import App from "./App.vue";
import { initDirective } from "./directive";
import router from "./router";
import store from "./store";

// import { amountFmt } from "./utils/format";

// 控制台
if (!["development", "production"].includes(process.env.VUE_APP_ENV)) {
  new VConsole();
}
// new VConsole();
console.log("当前环境变量-------------->", process.env.VUE_APP_ENV);
console.log("当前接口地址-------------->", process.env.VUE_APP_MCH_API);
const app = createApp(App);
initDirective(app);
if (!localStorage.getItem(LocalStorageEnum.ROLE)) {
  localStorage.setItem(LocalStorageEnum.ROLE, "Operator");
  localStorage.setItem(LocalStorageEnum.JWT_APPID, "MCH_MANAGE");
}
// 解决ios系统微信浏览器地址栏地址不改变导致wx.config签名失败
localStorage.setItem(LocalStorageEnum.JS_API_URL, location.href.split("#")[0]);
// 省市区接口数据
getCasToken() && store.dispatch("app/getAreaListAction");
// 刷新获取认证状态
getCasToken() && store.dispatch("app/getCertStatusAction");
// 权限校验
app.provide("useAuth", useAuth);
app.component("Page", Page);
app
  .use(store)
  .use(router)
  .use(Button)
  .use(ConfigProvider)
  .use(Icon)
  .use(Tabbar)
  .use(TabbarItem)
  .use(DatetimePicker)
  .use(Popup)
  .use(VanImage)
  .use(Cell)
  .use(CellGroup)
  .use(Search)
  .use(Field)
  .use(Form)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Switch)
  .use(List)
  .use(Sticky)
  .use(Dialog)
  .use(Picker)
  .use(Empty)
  .use(Collapse)
  .use(CollapseItem)
  .use(Loading)
  .use(Uploader)
  .use(Area)
  .use(Step)
  .use(Steps)
  .use(Overlay)
  .use(Skeleton)
  .use(NoticeBar)
  .use(PullRefresh)
  .use(Stepper)
  .use(Tab)
  .use(Tabs)
  .use(Col)
  .use(Row)
  .use(RadioGroup)
  .use(Radio)
  .use(ActionSheet)
  .mount("#app");
