<template>
  <Page :is-header="false">
    <component :is="Role" />
    <div class="u-p-b-70" />
  </Page>
</template>

<script>
import { defineComponent, computed, onActivated } from "vue";
import Operator from "./components/Operator.vue";
import Vendor from "./components/Vendor.vue";
import { useActions } from "@/hooks/useVuex";
import { getCurrentRole } from "@/hooks/useRole";
export default defineComponent({
  name: "Home",
  components: {
    Operator,
    Vendor,
  },
  setup() {
    const { getCertStatusAction } = useActions("app", ["getCertStatusAction"]);
    onActivated(() => {
      getCertStatusAction();
    });
    const Role = computed(() => {
      return getCurrentRole();
    });
    return {
      Role,
    };
  },
});
</script>

<style lang="scss" scoped></style>
