<template>
  <div class="page">
    <router-view v-slot="{ Component }">
      <component v-if="route.meta.noAlive" :is="Component" />
      <keep-alive v-else :max="3" :exclude="[]">
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <Tabbar v-show="tabbarPages" />
  </div>
</template>

<script>
import Tabbar from "@/components/Tabbar";
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
  components: {
    Tabbar,
  },
  setup() {
    const route = useRoute();
    const tabbarPages = computed(() => {
      const PageNames = ["Home", "User"];
      return PageNames.includes(route.name);
    });
    return {
      route,
      tabbarPages,
    };
  },
});
</script>
<style lang="scss">
.page {
  font-size: 16px;
}
</style>
