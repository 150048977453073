import {
  getIncomeDeviceDetailApi,
  getIncomeSalesApi,
  getIncomeSiteDetailApi,
} from "@/api/income";
// import { useAuth } from "@/hooks/useAuth";
// import { Toast } from "vant";
export default {
  namespaced: true,
  state: {
    salesReport: {}, // 销售额报告
    reportDetail: {}, // 场地，设备详情报告
  },
  mutations: {
    SET_SALES_REPORT(state, payload) {
      state.salesReport = payload;
    },
    SET_REPORT_DETAIL(state, payload) {
      state.reportDetail = payload;
    },
  },
  actions: {
    async getSalesReportAction({ commit }, payload) {
      try {
        const { content, code } = await getIncomeSalesApi({
          ...payload,
        });
        if (code === 200) {
          console.log("content", content);
          commit("SET_SALES_REPORT", content);
          return Promise.resolve(true);
        }
      } catch (error) {
        console.log("error", error);
        return Promise.reject(false);
      }
    },
    async getReportDetailAction({ commit }, payload) {
      try {
        let action = null;
        let params = {
          beginDate: payload.startTime,
          endDate: payload.endTime,
        };
        if (payload.siteId) {
          action = getIncomeSiteDetailApi;
          params = {
            ...params,
            siteId: payload.siteId,
          };
        }
        if (payload.deviceId) {
          action = getIncomeDeviceDetailApi;
          params = {
            ...params,
            deviceId: payload.deviceId,
          };
        }
        const { content, code } = await action({
          ...params,
        });
        if (code === 200) {
          commit("SET_REPORT_DETAIL", content);
          return Promise.resolve(true);
        }
      } catch (error) {
        console.log("error", error);
        return Promise.reject(false);
      }
    },
  },
  getters: {
    salesReport(state) {
      return state.salesReport;
    },
    reportDetail(state) {
      return state.reportDetail;
    },
  },
};
