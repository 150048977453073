import request from "@/request/interceptors";
// 获取手机验证码
export function getGoodsList(params) {
  return request({
    url: "mch/goods/info/v1/page",
    method: "get",
    params,
  });
}
export function insertGoods(data) {
  return request({
    url: "mch/goods/info/v1/insert",
    method: "post",
    data,
  });
}
export function updateGoods(data) {
  return request({
    url: "mch/goods/info/v1/update",
    method: "post",
    data,
  });
}

export function deleteGoods(params) {
  return request({
    url: "mch/goods/info/v1/delete",
    method: "get",
    params,
  });
}
