import request from "@/request/interceptors";

// 获取订单列表
export function getOrderListByPageApi(params) {
  return request({
    url: "mch/data/deviceOrder/v1/list/page",
    method: "GET",
    params,
  });
}

// 获取订单详情
export function getOrderDetailApi(params) {
  return request({
    url: "mch/data/deviceOrder/v1/detail",
    method: "GET",
    params,
  });
}

// 获取启动记录列表
export function getStartRecordByPageApi(params) {
  return request({
    url: "mch/data/coinRecord/v1/list/page",
    method: "GET",
    params,
  });
}

// 获取礼品记录列表
export function getGiftRecordApi(params) {
  return request({
    url: "mch/data/hitGift/v1/statistics",
    method: "GET",
    params,
  });
}

// 获取投币记录列表
export function getCoinRecordApi(params) {
  return request({
    url: "mch/data/coinRecord/v1/statistics",
    method: "GET",
    params,
  });
}

// 申请退款
export function addRefundApplyApi(data) {
  return request({
    url: "mch/data/refundOrder/v1/refund",
    method: "POST",
    data,
  });
}

// 获取退款记录列表
export function getRefundRecordApi(params) {
  return request({
    url: "mch/data/refundOrder/v1/list/page",
    method: "GET",
    params,
  });
}

// 获取退款记录详情
export function getRefundOrderDetailApi(params) {
  return request({
    url: "mch/data/refundOrder/v1/detail",
    method: "GET",
    params,
  });
}
