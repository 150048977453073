export const LocalStorageEnum = {
  BIND_INFO: "BIND_INFO", // 微信绑定相关信息，手机号啥的
  CAS_TOKEN: "CAS_TOKEN", // 临时登录token
  OPEN_ID: "OPEN_ID", // openId
  UNION_ID: "UNION_ID", // unionId
  WX_INFO: "WX_INFO", // 微信的信息，包括头像，昵称
  USER_LIST: "USER_LIST", // 多账号登录的账户列表
  JWT_APPID: "JWT_APPID", // jwtAppId
  ROLE: "ROLE", // 角色 运营商：Operator、厂商：Vender
  TOKEN_EXP: "TOKEN_EXP", // 登录临时Token有效时间
  JS_CONFIG: "JS_CONFIG", // js config 信息
  USER: "USER", // 用户
  RULES: "RULES", // 权限码
  AUTH: "AUTH", // 用户全部的信息
  INVITATION_CODE: "INVITATION_CODE", // 邀请码
  JS_API_URL: "JS_API_URL", // wx.config的当前url
};

export const getLocalStoreItem = (key) => {
  return localStorage.getItem(LocalStorageEnum[key]);
};

export const setLocalStoreItem = (key, value) => {
  return localStorage.setItem(LocalStorageEnum[key], value);
};

// 常用的本地缓存
// 获取OPENID
export const getOpenId = () => {
  return getLocalStoreItem("OPEN_ID");
};

// 获取unionId
export const getUnionId = () => {
  return getLocalStoreItem("UNION_ID");
};

// 获取casToken
export const getCasToken = () => {
  return getLocalStoreItem("CAS_TOKEN");
};

// 获取jwtAppId
export const getJwtAppId = () => {
  return getLocalStoreItem("JWT_APPID");
};
