const OperatorDevice = () => import("@/views/device/operator.vue");
const VendorDevice = () => import("@/views/device/vendor.vue");
export default [
  {
    path: "/device/operator",
    name: "OperatorDevice",
    meta: {
      title: "设备列表",
      check: true,
    },
    component: OperatorDevice,
  },
  {
    path: "/device/vendor",
    name: "VendorDevice",
    meta: {
      title: "设备列表",
      check: true,
    },
    component: VendorDevice,
  },
  {
    path: "/device/detail/operator",
    name: "DeviceDetail",
    meta: {
      title: "设备详情",
      check: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "device" */ "@/views/device/detail/operator.vue"
      ),
  },
  {
    path: "/device/detail/vendor",
    name: "VendorDeviceDetail",
    meta: {
      title: "设备详情",
      check: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "device" */ "@/views/device/detail/vendor.vue"
      ),
  },
  {
    path: "/device/register/scan",
    name: "RegisterScan",
    meta: {
      title: "设备注册",
      check: true,
      isScan: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "device" */ "@/views/device/register/scan.vue"
      ),
  },
  {
    path: "/device/register/bind",
    name: "DeviceBind",
    meta: {
      title: "设备绑定",
      check: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "device" */ "@/views/device/register/bind.vue"
      ),
  },
  {
    path: "/device/register/success",
    name: "RegisterSuccess",
    meta: {
      title: "绑定成功",
      check: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "device" */ "@/views/device/register/success.vue"
      ),
  },
  {
    path: "/device/setting/gift",
    name: "DeviceGift",
    meta: {
      title: "礼品设置",
      check: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "device" */ "@/views/device/setting/gift.vue"
      ),
  },
  {
    path: "/device/setting/hardware",
    name: "DeviceHardware",
    meta: {
      title: "硬件设置",
      check: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "device" */ "@/views/device/setting/hardware.vue"
      ),
  },
  {
    path: "/device/setting/start-test",
    name: "DeviceStartTest",
    meta: {
      title: "启动测试",
      check: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "device" */ "@/views/device/setting/test.vue"
      ),
  },
  {
    path: "/device/setting/info",
    name: "DeviceInfo",
    meta: {
      title: "基本信息",
      check: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "device" */ "@/views/device/setting/info.vue"
      ),
  },
  {
    path: "/device/record/coin-record",
    name: "StartRecord",
    meta: {
      title: "投币记录",
      check: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "device" */ "@/views/records/coin-record.vue"
      ),
  },
  {
    path: "/device/record/coin-summary",
    name: "CoinTotalRecord",
    meta: {
      title: "投币汇总",
      check: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "device" */ "@/views/records/coin-summary.vue"
      ),
  },
  {
    path: "/device/record/gift-record",
    name: "GiftRecord",
    meta: {
      title: "礼品记录",
      check: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "device" */ "@/views/records/gift-record.vue"
      ),
  },
  {
    path: "/device/setting/control",
    name: "DeviceMainControl",
    meta: {
      title: "更换主控",
      check: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "device" */ "@/views/device/setting/mainControl.vue"
      ),
  },
];
