const User = () => import("@/views/user/index.vue");
export default [
  {
    path: "/user",
    name: "User",
    meta: {
      title: "我的",
      check: true,
    },
    component: User,
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "登录",
      check: false,
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/login/login.vue"),
  },
  {
    path: "/login/bind",
    name: "LoginBind",
    meta: {
      title: "绑定微信号",
      check: false,
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/login/bind.vue"),
  },
  {
    path: "/login/choose",
    name: "LoginChoose",
    meta: {
      title: "选择账号登录",
      check: false,
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/login/choose.vue"),
  },
  {
    path: "/login/vertify",
    name: "LoginVertify",
    meta: {
      title: "登录验证",
      check: false,
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/login/vertify.vue"),
  },
  {
    path: "/forget",
    name: "Forget",
    meta: {
      title: "忘记密码",
      check: false,
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/login/forget.vue"),
  },
  {
    path: "/register",
    name: "Register",
    meta: {
      title: "注册",
      check: false,
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/login/register.vue"),
  },
  {
    path: "/protocol",
    name: "Protocol",
    meta: {
      title: "协议",
      check: false,
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/login/protocol.vue"),
  },
  {
    path: "/user/setting",
    name: "UserSetting",
    meta: {
      title: "设置",
      check: true,
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/user/setting/index.vue"),
  },
  {
    path: "/user/certification",
    name: "Certification",
    meta: {
      title: "实名认证",
      check: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "user" */ "@/views/user/certification/index.vue"
      ),
  },
  {
    path: "/user/certification/status",
    name: "CertificationStatus",
    meta: {
      title: "实名认证",
      check: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "user" */ "@/views/user/certification/status.vue"
      ),
  },
  {
    path: "/point/index",
    name: "PointList",
    meta: {
      title: "场地管理",
      check: true,
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/user/point/index.vue"),
  },
  {
    path: "/point/add",
    name: "PointAdd",
    meta: {
      title: "新增场地",
      check: true,
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/user/point/add.vue"),
  },
  {
    path: "/sub-account/index",
    name: "SubAccountList",
    meta: {
      title: "子账号管理",
      check: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "user" */ "@/views/user/subAccount/index.vue"
      ),
  },
  {
    path: "/sub-account/add",
    name: "SubAccountAdd",
    meta: {
      title: "新增子账号",
      check: true,
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/views/user/subAccount/add.vue"),
  },
];
