import {
  bindWeixinApi,
  getUserAuthInfoApi,
  getWeixinOpenIdApi,
  getWxBindListApi,
  getWxBindListByUserIdApi,
  loginByUserIdApi,
  mchUserLoginApi,
  unbindApi,
  verifyLoginApi,
} from "@/api/user";
import { isOperator } from "@/hooks/useRole";

import {
  factoryBindWeixinApi,
  factoryGetAuthInfoApi,
  factoryGetWeixinOpenIdApi,
  factoryGetWxBindListApi,
  factoryGetWxBindListByUserIdApi,
  factoryLoginByUserIdApi,
  factoryUnbindApi,
  factoryUserLoginApi,
  factoryVerifyLoginApi,
} from "@/api/vendor";
import {
  getJwtAppId,
  getOpenId,
  getUnionId,
  LocalStorageEnum,
  setLocalStoreItem,
} from "@/hooks/useLocalStorage";
import router from "@/router";
import { Dialog, Toast } from "vant";
export default {
  namespaced: true,
  state: {
    loginInfo: {
      thirdUserId: "",
      unionId: "",
      userName: "",
      headImgUrl: "",
    },
    bindAuthInfo: {
      phone: "",
      userId: "",
      userType: "",
    },
    invitationCode: "", // 注册邀请码
  },
  mutations: {
    SET_LOGIN_INFO(state, data) {
      state.loginInfo = data;
    },
    SET_BIND_AUTH_INFO(state, data) {
      localStorage.setItem(LocalStorageEnum.BIND_INFO, JSON.stringify(data));
      state.bindAuthInfo = data;
    },
    SET_INVITATION_CODE(state, payload) {
      state.invitationCode = payload;
      localStorage.setItem(LocalStorageEnum.INVITATION_CODE, payload);
    },
  },
  actions: {
    // 重置登录
    resetLogin({ commit }) {
      const RemoveKeys = [
        LocalStorageEnum.CAS_TOKEN,
        LocalStorageEnum.AUTH,
        LocalStorageEnum.TOKEN_EXP,
        LocalStorageEnum.RULES,
        LocalStorageEnum.USER,
        LocalStorageEnum.USER_LIST,
      ];
      commit("SET_LOGIN_INFO", {
        thirdUserId: "",
        unionId: "",
        userName: "",
        headImgUrl: "",
      });
      commit("SET_BIND_AUTH_INFO", {
        phone: "",
        userId: "",
        userName: "",
      });
      RemoveKeys.forEach((key) => {
        localStorage.removeItem(key);
      });
      router.replace("/login");
    },
    // 设置邀请码
    setInvitationCode({ commit }, payload) {
      commit("SET_INVITATION_CODE", payload);
    },
    // 获取openId
    async getWeixinOpenIdAction({ commit }, payload) {
      try {
        const action = isOperator()
          ? getWeixinOpenIdApi
          : factoryGetWeixinOpenIdApi;
        const { content, code } = await action({
          code: payload.code,
        });
        if (code === 200) {
          const {
            thirdUserId,
            unionId,
            consumerInfo: { nickName, headImgUrl },
          } = content;
          localStorage.setItem(LocalStorageEnum.OPEN_ID, thirdUserId);
          localStorage.setItem(LocalStorageEnum.UNION_ID, unionId);
          localStorage.setItem(
            LocalStorageEnum.WX_INFO,
            JSON.stringify({ userName: nickName, headImgUrl })
          );
          commit("SET_LOGIN_INFO", content);
          return Promise.resolve(content);
        }
      } catch (error) {
        console.log("error", error);
        return Promise.reject(error);
      }
    },
    // 检查绑定关系
    async checkWeixinBindAccountAction({ state, dispatch }) {
      try {
        const action = isOperator()
          ? getWxBindListApi
          : factoryGetWxBindListApi;
        const { content, code } = await action({
          unionId: state.loginInfo.unionId || getUnionId(),
          jwtAppId: getJwtAppId(),
          thirdUserType: isOperator() ? 1 : 2,
        });
        if (code === 200) {
          const { num, userList } = content;
          if (num > 1) {
            // 多账号
            console.log("userList", userList);
            localStorage.setItem(
              LocalStorageEnum.USER_LIST,
              JSON.stringify(userList)
            );
            router.replace("/login/choose");
          } else if (num < 1) {
            // 未绑定
            router.replace("/login");
          } else {
            // 已绑定
            dispatch("afterLoginAction", content);
          }
          return Promise.resolve(content);
        }
      } catch (error) {
        console.log("error", error);
        return Promise.reject(error);
      }
    },
    // 登陆之后
    async afterLoginAction({ dispatch }, payload) {
      try {
        const { accessToken, exp, isNoLogin } = payload;
        localStorage.setItem(LocalStorageEnum.CAS_TOKEN, accessToken);
        localStorage.setItem(LocalStorageEnum.TOKEN_EXP, exp);
        if (isNoLogin) return false; // 是否不需要登录，如果不需要登录，就只保存token（为了兼容暂不绑定）
        await dispatch("getUserAuthDetail");
        dispatch("app/getAreaListAction", null, { root: true });
        router.replace("/");
      } catch (error) {
        console.log("error", error);
      }
    },
    // 获取用户权限相关
    async getUserAuthDetail() {
      try {
        const authAction = isOperator()
          ? getUserAuthInfoApi
          : factoryGetAuthInfoApi;
        const { code, content } = await authAction();
        if (code === 200) {
          console.log("content", content);
          setLocalStoreItem("AUTH", JSON.stringify(content));
          setLocalStoreItem("RULES", JSON.stringify(content.ruleSet));
          console.log("222", 222);
          Promise.resolve(content);
        }
      } catch (error) {
        console.log("error", error);
        Promise.reject(false);
      }
    },
    // 账号密码登录
    async loginByUsernameAction({ dispatch, state }, payload) {
      try {
        const action = isOperator() ? mchUserLoginApi : factoryUserLoginApi;
        const { content, code } = await action({
          ...payload,
          unionId: state.loginInfo.unionId || getUnionId(),
          jwtAppId: getJwtAppId(),
          thirdUserType: isOperator() ? 1 : 2,
          platformAuthType: "Manage",
        });
        // 用户名或密码错误

        if (code === 200) {
          dispatch("checkBindStatus", content);
        }
      } catch (error) {
        console.log("error", error);
        if (error.code === 10001) Toast("账号密码错误");
        if (error.code === 10039) {
          if (error.message < 5) {
            Toast(`用户名或密码错误，今天还可以重试${4 - error.message}次`);
          } else {
            Toast("您已经连续输错5次，请1小时后再登录吧");
          }
        }
        return Promise.reject(error);
      }
    },
    // 通过userId登录，选择账号登录
    async loginByUserIdAction({ dispatch, state }, payload) {
      try {
        const action = isOperator()
          ? loginByUserIdApi
          : factoryLoginByUserIdApi;
        const { content, code } = await action({
          ...payload,
          unionId: state.loginInfo.unionId || getUnionId(),
          jwtAppId: getJwtAppId(),
        });
        if (code === 200) {
          dispatch("afterLoginAction", content);
          localStorage.setItem(LocalStorageEnum.USER_LIST, JSON.stringify([]));
        }
      } catch (error) {
        console.log("error", error);
        return Promise.reject(error);
      }
    },
    // 账密登录检查绑定状态
    async checkBindStatus({ commit, dispatch }, content) {
      console.log("检查登录状态---------", content);
      const { phone, userId, userName, accessToken, exp, loginBussinessType } =
        content;
      await commit("SET_BIND_AUTH_INFO", {
        phone,
        userId,
        userName,
      });
      let isNoBind = 0; // 是否显示暂不绑定按钮

      // loginBussinessType = 0：直接登录(accessToken有值)
      // loginBussinessType = 1：走绑定逻辑(此时账号未做绑定)
      // loginBussinessType = 2：授权登录(提供临时登录，需要验证手机号)
      // loginBussinessType = 3：IP异常登录(需要验证手机号)
      // loginBussinessType = 4：重新绑定(也是走绑定流程)
      switch (loginBussinessType) {
        case 0:
          // 直接登录
          dispatch("afterLoginAction", {
            accessToken,
            exp,
          });
          break;
        case 1:
        case 4:
          if (loginBussinessType === 1) {
            // 去绑定，兼容暂不绑定，这个时候先保存token,但是不登录， 给暂不绑定直接登录使用
            dispatch("afterLoginAction", {
              accessToken,
              exp,
              isNoLogin: true,
            });
            isNoBind = 1; // 需要去绑定就需要显示暂不绑定
          }
          // 去绑定或者更换绑定
          router.push({
            path: "/login/bind",
            query: {
              bindType: loginBussinessType,
              isNoBind,
            },
          });
          break;
        case 2:
        case 3:
          // 是否绑定的当前微信号
          console.log("PhoneVerifity");
          router.push({
            path: "/login/vertify",
            query: {
              loginBussinessType: loginBussinessType,
            },
          });
          break;
      }
    },
    // 绑定微信号
    async bindWeixinAction({ state, dispatch }, payload) {
      try {
        const WeixinInfo = JSON.parse(
          localStorage.getItem(LocalStorageEnum.WX_INFO)
        );
        const BindInfo = JSON.parse(
          localStorage.getItem(LocalStorageEnum.BIND_INFO)
        );
        let data = {
          userId: state.bindAuthInfo.userId || BindInfo.userId,
          openId: state.loginInfo.thirdUserId || getOpenId(),
          code: payload.vcode,
          unionId: state.loginInfo.unionId || getUnionId(),
          jwtAppId: getJwtAppId(),
          thirdUserType: isOperator() ? 1 : 2,
          nickName: WeixinInfo.userName,
          headImgUrl: WeixinInfo.headImgUrl,
          loginBussinessType: payload.bindType,
        };
        const action = isOperator() ? bindWeixinApi : factoryBindWeixinApi;
        const { code, content } = await action({
          ...data,
        });
        if (code === 200) {
          if (payload.bindType == 1) {
            Toast("绑定成功");
            dispatch("afterLoginAction", content);
          } else {
            Toast("更换绑定成功");
            localStorage.setItem(
              LocalStorageEnum.CAS_TOKEN,
              content.accessToken
            );
            router.back();
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    // 校验登录
    async verifyPhoneLoginAction({ state, dispatch }, payload) {
      try {
        const BindInfo = JSON.parse(
          localStorage.getItem(LocalStorageEnum.BIND_INFO)
        );
        let data = {
          userId: state.bindAuthInfo.userId || BindInfo.userId,
          code: payload.vcode,
          jwtAppId: getJwtAppId(),
        };
        const action = isOperator() ? verifyLoginApi : factoryVerifyLoginApi;
        const { code, content } = await action({
          ...data,
        });
        if (code === 200) {
          Toast("校验成功");
          dispatch("afterLoginAction", content);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async checkWeixinBind({ dispatch, commit, state }) {
      try {
        const action = isOperator()
          ? getWxBindListByUserIdApi
          : factoryGetWxBindListByUserIdApi;
        const unionId = state.loginInfo.unionId || getUnionId();
        const { code, content } = await action({
          unionId,
          jwtAppId: getJwtAppId(),
          thirdUserType: isOperator() ? 1 : 2,
        });
        if (code === 200) {
          if (content.num) {
            // 已绑定
            const lastWxInfo = content.userList[0];
            if (lastWxInfo.unionId === unionId) {
              // 已绑定当前微信号，需要解除绑定
              Dialog.confirm({
                title: "温馨提示",
                message: `解绑微信后你将无法使用微信快捷登录， 你确定要解除绑定吗？`,
                confirmButtonColor: "#027AFF",
                async beforeClose(action) {
                  if (action === "confirm") {
                    try {
                      const action = isOperator()
                        ? unbindApi
                        : factoryUnbindApi;
                      const { code } = await action({
                        thirdUserType: isOperator() ? 1 : 2,
                      });
                      if (code === 200) {
                        Toast("解除绑定成功");
                        dispatch("resetLogin");
                        return Promise.resolve(true);
                      }
                    } catch (error) {
                      console.log("error", error);
                      return Promise.resolve(false);
                    }
                  } else {
                    return Promise.resolve(true);
                  }
                },
              });
            } else {
              // 已绑定其他微信号，需要更换绑定
              Dialog.confirm({
                title: "温馨提示",
                message: `当前账号已绑定微信“${lastWxInfo.nickName}”, 确定继续绑定当前微信？`,
                confirmButtonColor: "#027AFF",
                beforeClose(action) {
                  if (action === "confirm") {
                    // 确认跳转绑定微信页面
                    commit("SET_BIND_AUTH_INFO", {
                      phone: lastWxInfo.phone,
                      userId: lastWxInfo.userId,
                      userName: lastWxInfo.userName,
                    });
                    router.push({
                      path: "/login/bind",
                      query: {
                        bindType: 4,
                        isNoBind: 0,
                      },
                    });
                    return Promise.resolve(true);
                  } else {
                    return Promise.resolve(true);
                  }
                },
              });
            }
          } else {
            // Toast("暂无绑定关系");
            router.push({
              path: "/login/bind",
              query: {
                bindType: 1,
                isNoBind: 0,
              },
            });
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  },
  getters: {},
};
