import request from "@/request/interceptors";
export function packageTmpList(params) {
  return request({
    url: "mch/user/marketing/template/v1/list",
    method: "GET",
    params,
  });
}

export function packageTmpDetail(params) {
  return request({
    url: "mch/user/marketing/template/v1/detail",
    method: "GET",
    params,
  });
}
export function addPackageTmp(data) {
  return request({
    url: "mch/user/marketing/template/v1/insert",
    method: "POST",
    data,
  });
}
export function updatePackageTmp(data) {
  return request({
    url: "mch/user/marketing/template/v1/update",
    method: "POST",
    data,
  });
}

export function templateSetSit(data) {
  return request({
    url: "mch/user/mchInfo/mchSite/v1/set/template",
    method: "POST",
    data,
  });
}

// 删除一条套餐模板明细
export function deleteTmpItem(params) {
  return request({
    url: "mch/user/marketing/template/v1/delete/templateItem",
    method: "GET",
    params,
  });
}
// 修改一条套餐模板明细
export function updateTmpItem(data) {
  return request({
    url: "mch/user/marketing/template/v1/update/templateItem",
    method: "POST",
    data,
  });
}
// 新增一条套餐模板明细
export function insertTmpItem(data) {
  return request({
    url: "mch/user/marketing/template/v1/insert/templateItem",
    method: "POST",
    data,
  });
}

// 启用禁用当前套餐
export function updateTmpStatus(data) {
  return request({
    url: "mch/user/marketing/template/v1/update/status",
    method: "POST",
    data,
  });
}
// 设置推荐
export function updateRecommend(params) {
  return request({
    url: "mch/user/marketing/template/v1/update/recommend",
    method: "GET",
    params,
  });
}
