import { LocalStorageEnum } from "@/hooks/useLocalStorage";
import request from "@/request/interceptors";
const platformURL = process.env.VUE_APP_PLFM_API; // 平台服务的接口需要加上平台服务的前缀地址

// 获取手机验证码
export function getVCodeApi(data) {
  return request({
    url: "mch/base/sms/v1/vcode",
    method: "post",
    data,
  });
}

// 获取手机验证码白名单
export function getVCodeWhiteApi(data) {
  return request({
    url: "mch/base/sms/v1/vcode/white",
    method: "post",
    data,
  });
}
// 获取需要手机号的短信验证码白名单
export function getVCodeWhiteByPhoneApi(data) {
  return request({
    url: platformURL + "platform/base/sms/v1/send/vCode/white",
    method: "post",
    data,
  });
}

// 校验验证码
export function verifyVcodeByCodeApi(data) {
  return request({
    url: "mch/base/sms/v1/verify",
    method: "post",
    data,
  });
}

// 省市区
export function getAreaListApi(params) {
  return request({
    url: platformURL + "platform/base/area/v1/area/tree",
    method: "get",
    params,
  });
}

// 获取微信jsapi签名检验
export function weinxinConfigApi(params) {
  return request({
    url: platformURL + "platform/base/thirdAuth/wechat/jsapi/v1",
    method: "get",
    params: {
      url: localStorage.getItem(LocalStorageEnum.JS_API_URL),
      ...params,
    },
  });
}

//# todo 删除多余的URl
// 商品图片上传
export function uploadGoodsImg(data) {
  return request({
    url: platformURL + "platform/base/upload/v1/file",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
}
