import {
  deleteGoods,
  getGoodsList,
  insertGoods,
  updateGoods,
} from "@/api/goods";
import { useAuth } from "@/hooks/useAuth";
import { Toast } from "vant";
export default {
  namespaced: true,
  state: {
    goodsList: [],
    totalSize: 0,
    updateIndex: -1,
  },
  mutations: {
    SET_GOODSLIST(state, payload) {
      state.goodsList = [...payload];
    },
    SET_TOTALSIZE(state, payload) {
      state.totalSize = payload;
    },
    SET_UPDATEINDEX(state, payload) {
      state.updateIndex = payload;
    },
    SET_UPDATEVALUE(state, payload) {
      state.goodsList[state.updateIndex] = payload;
    },
    DELETE_GOODS(state, payload) {
      state.goodsList.splice(payload, 1);
    },
  },
  actions: {
    async goodsListAction({ commit, state }, payload) {
      console.log("useAuth", useAuth);
      if (!useAuth("B030201")) {
        Toast("暂无权限");
        return false;
      }
      const {
        content: { totalSize, currentList },
      } = await getGoodsList({
        ...payload,
      });
      commit(
        "SET_GOODSLIST",
        payload.page === 1 ? currentList : state.goodsList.concat(currentList)
      );
      commit("SET_TOTALSIZE", totalSize);
    },
    async addGoodsAction({ commit }, payload) {
      console.log(commit);
      let url = payload.skuId ? updateGoods : insertGoods;
      return await url({
        ...payload,
      });
    },
    async deleteGoodsActions({ commit }, payload) {
      console.log(commit);
      return await deleteGoods({
        ...payload,
      });
    },
  },
  getters: {},
};
