import { getCasToken, LocalStorageEnum } from "@/hooks/useLocalStorage";
import router from "@/router";
import store from "@/store";
import axios from "axios";
import { Toast } from "vant";
import Sign from "./sign.js";
axios.defaults.baseURL = process.env.VUE_APP_MCH_API; // 默认是商户服务的前缀地址，如果需要修改可以在定义apiurl的时候加上相对应服务的地址
axios.defaults.withCredentials = false;
axios.defaults.timeout = 60000; //60s
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
axios.interceptors.request.use(
  (config) => {
    // 页面是否校验token
    const { check } = router.currentRoute.value.meta;
    const TokenExp = localStorage.getItem(LocalStorageEnum.TOKEN_EXP) || 0;
    // 判断token是否过期
    if (check && new Date().getTime() > TokenExp) {
      Toast("登录状态已过期，请重新登录");
      store.dispatch("user/resetLogin");
      return new Error("登录状态已过期，请重新登录");
    }
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json;charset=UTF-8",
      "Tianlu-User-Access-Token": getCasToken() || "",
    };
    const signOptions = Sign(config);
    config.headers = {
      ...config.headers,
      ...signOptions,
    };
    console.log(
      `%c<-------${config.url}-------->`,
      "color: red;font-size: 18px",
      config.data || config.params
    );
    return config;
  },
  (error) => {
    Toast("请求方式错误");
    return Promise.reject(error);
  }
);
//code状态码200判断
axios.interceptors.response.use(
  (response) => {
    const { code, message } = response.data;
    console.log(
      `%c<-------${response.config.url}-------->`,
      "color: green;font-size: 18px",
      response.data
    );
    if (code == 10002 || code == 10006 || code == 10005 || code == 10034) {
      // 10002 token已经过期，10006登录状态失效
      Toast("登录状态已过期，请重新登录");
      store.dispatch("user/resetLogin");
      return response.data;
    }
    // 权限校验返回失败
    if (code == 10003) {
      Toast("抱歉暂无访问权限");
      return Promise.reject(response.data);
    }
    // 不需要Toast接口错误message的code
    const WHITE_CODES = [83001, 10001, 10039];
    if (
      (response && response.data && code < 0) ||
      (code && code !== 200 && code !== 100)
    ) {
      // 更改错误码，增加情况，当code不为100，不为200，且code存在的时候
      if (!WHITE_CODES.includes(code) && message) {
        Toast(message);
      }
      return Promise.reject(response.data);
    }
    return response.data;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          console.log("接口返回401！！！！！！！！！！！！！");
          return Promise.reject(error.response.data); // 返回接口返回的错误信息
      }
      if (error.response.status !== 400) {
        Toast(error.message);
      }
    } else {
      Toast(
        error.code !== "ECONNABORTED" ? error.message : "加载超时，请稍后重试"
      );
    }

    return Promise.reject(error);
  }
);

export default axios;
