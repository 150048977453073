<template>
  <div v-if="VendorMenusList.length" class="content">
    <div
      v-for="menu in VendorMenusList"
      :key="menu.path"
      class="menu-item u-flex bg-white u-p-16 u-m-t-12 u-radius-4"
      @click="handleClickMenu(menu)"
    >
      <van-icon
        class-prefix="iconfont"
        :name="menu.icon"
        size="48"
        color="#027AFF"
      />
      <div class="u-flex-1 u-m-l-10">
        <p>{{ menu.title }}</p>
        <p class="u-m-t-10 u-font-14 u-content-color">{{ menu.subTitle }}</p>
      </div>
    </div>
  </div>
  <van-empty v-else image="error" description="暂无权限" />
  <!-- 认证tips -->
  <CertTips auth="C0201" />
  <!-- 指南 -->
  <Guide href="https://support.qq.com/products/365786/faqs-more?" />
</template>

<script>
import { defineComponent, ref, onActivated, inject } from "vue";
import { VendorMenus } from "../const";
import { useRouter } from "vue-router";
import { useState } from "@/hooks/useVuex";
import CertTips from "./CertTips.vue";
import Guide from "./Guide.vue";
export default defineComponent({
  name: "Vendor",
  components: {
    CertTips,
    Guide,
  },
  setup() {
    const router = useRouter();
    const useAuth = inject("useAuth");
    const VendorMenusList = ref([]);
    const { mchCertStatus } = useState("app", ["mchCertStatus"]);
    onActivated(() => {
      VendorMenusList.value = VendorMenus.filter((m) => {
        return useAuth(m.auth);
      });
    });
    const handleClickMenu = (menu) => {
      console.log(menu);
      if (menu.key === "vertifi" && mchCertStatus.value.status) {
        router.push({
          path: "/user/certification/status",
          query: {
            status: mchCertStatus.value.status,
            rejectReason: mchCertStatus.value.rejectReason,
          },
        });
        return false;
      }
      if (menu.path) {
        router.push({
          path: menu.path,
        });
      }
    };
    return {
      VendorMenusList,
      handleClickMenu,
    };
  },
});
</script>

<style lang="scss" scoped></style>
