<template>
  <van-tabbar v-model="active" route inactive-color="#333333">
    <van-tabbar-item
      v-for="tab in Tabs"
      :key="tab.to.name"
      replace
      :to="tab.to"
    >
      <span>{{ tab.name }}</span>
      <template #icon="props">
        <img class="tab-icon" :src="props.active ? tab.active : tab.inactive" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>
<script>
import { defineComponent, ref } from "vue";
const Tabs = [
  {
    name: "首页",
    inactive: require("@/assets/images/ic_home.png"),
    active: require("@/assets/images/ic_home_active.png"),
    to: {
      name: "Home",
    },
  },
  {
    name: "我的",
    inactive: require("@/assets/images/ic_wode.png"),
    active: require("@/assets/images/ic_wode_active.png"),
    to: {
      name: "User",
    },
  },
];
export default defineComponent({
  name: "Tabbar",
  components: {},
  setup() {
    const active = ref(0);
    const handleChange = (a, index) => {
      console.log("当前标签", a, index);
      active.value = index;
    };
    return {
      Tabs,
      active,
      handleChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.tab-icon {
  width: 24px;
  height: 24px;
}
</style>
