import { float } from "./float";

// 手机脱敏切割
export const phoneFmt = (value) => {
  if (!value) return "";
  return value.toString().replace(/^(\d{3})\d{4}(\d{4})$/, "$1 **** $2");
};

/*
 * params: {String, Number}
 * 格式化金额
 */
export const amountFmt = (price, fixed = 2) => {
  if (!price) return "0";
  let value = float.divide(price, 100).toFixed(fixed);
  return value.replace(".00", "");
};

/*
 * params: {String, Number}
 * 格式化重量
 */
export const weightFmt = (weight, fixed = 3) => {
  if (!weight) return "0";
  let value = float.divide(weight, 1000).toFixed(fixed);
  return value.replace(".000", "");
};

/*
 * params: {String}
 * 支付状态格式化
 */
export const payStatusFmt = (status) => {
  const Fmt = {
    NO_PAY: "待支付",
    PAY_SUCCESS: "支付成功",
    AUTO_REFUND: "自动退款",
    SYS_REFUND: "人工退款",
    PART_REFUND: "部分退款",
  };
  return Fmt[status];
};

/*
 * params: {String}
 * 支付状态格式化
 */
export const shipmentFmt = (status) => {
  const Fmt = {
    NOT_FALL: "下货失败",
    FALL: "下货成功",
  };
  return Fmt[status];
};

/**
 * value  string | number
 * 成本价格式化
 */
export const formatterFixed3 = (value) => {
  let reg = /^(([1-9][0-9]*)|(([0]\.\d{1,3}|[1-9][0-9]*\.\d{1,3})))$/;
  let len = 6;
  let n = 0;
  if (value.includes(".")) {
    n = value.split(".").length - 1;
  }
  if ((!reg.test(value) && !value.endsWith(".") && value !== "0") || n > 1) {
    return value.substr(0, value.length - 1);
  } else {
    if (
      (value.length > len + 3 && value.includes(".")) ||
      (value.length > len && !value.includes(".")) ||
      value == "."
    ) {
      return value.substr(0, value.length - 1);
    }
  }
  return value;
};
/**
 * value  string | number
 * 销售价格式化
 */
export const formatterFixed2 = (value) => {
  let reg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
  let len = 6;
  let n = 0;
  if (value.includes(".")) {
    n = value.split(".").length - 1;
  }
  if ((!reg.test(value) && !value.endsWith(".") && value !== "0") || n > 1) {
    return value.substr(0, value.length - 1);
  } else {
    if (
      (value.length > len + 2 && value.includes(".")) ||
      (value.length > len && !value.includes(".")) ||
      value == "."
    ) {
      return value.substr(0, value.length - 1);
    }
  }
  return value;
};

/**
 * value  string | number
 * px2rem
 */
export const pxToRem = (value) => {
  if (/%$/.test(value) || value === "auto") return value;
  const valNum = /\d+/.exec(value);
  const num = Number(valNum ? valNum[0] : 0);
  return `${float.multiply(num, 0.02667).toFixed(2)}rem`;
};
