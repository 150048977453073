import { getJwtAppId } from "@/hooks/useLocalStorage";
import request from "@/request/interceptors";
const factoryURL = process.env.VUE_APP_FACT_API; // 平台服务的接口需要加上平台服务的前缀地址

// 获取手机验证码白名单
export function factoryGetVCodeWhiteApi(data) {
  return request({
    url: factoryURL + "factory/base/sms/v1/vcode/white",
    method: "post",
    data,
  });
}

// 厂商登录
export function factoryUserLoginApi(data) {
  return request({
    url: factoryURL + "factory/user/auth/v1/login",
    method: "post",
    data,
  });
}

// 厂商修改密码
export function factoryUpdatePasswordApi(data) {
  return request({
    url: factoryURL + "factory/user/userInfo/v1/password",
    method: "post",
    data,
  });
}

// 获取手机验证码
export function factoryGetVCodeApi(data) {
  return request({
    url: factoryURL + "factory/base/sms/v1/vcode",
    method: "post",
    data,
  });
}

// 厂商获取当前用户权限
export function factoryGetAuthInfoApi(params) {
  return request({
    url: factoryURL + "factory/user/auth/v1/auth",
    method: "get",
    params,
  });
}

// 厂商获取当前用户信息
export function factoryGetUserInfoApi(data) {
  return request({
    url: factoryURL + "factory/user/auth/v1/detail",
    method: "post",
    data,
  });
}

// 厂商获取当前用户权限
export function factoryLogoutApi(params) {
  return request({
    url: factoryURL + "factory/user/auth/v1/logOut",
    method: "get",
    params,
  });
}

// 厂商设备注册
export function factoryDeviceRegisterApi(data) {
  return request({
    url: factoryURL + "factory/device/register/v1",
    method: "post",
    data,
  });
}

// 检查imei是否绑定
export function factoryCheckDeviceBindApi(params) {
  return request({
    url: factoryURL + "factory/device/register/v1/register/check",
    method: "get",
    params,
  });
}

// 检查支付码是否绑定
export function factoryCheckQrcodeApi(params) {
  return request({
    url: factoryURL + "factory/device/register/v1/check/qrcode",
    method: "get",
    params,
  });
}

// 检查厂商实名认证状态
export function factoryCheckCertStatusApi(params) {
  return request({
    url: factoryURL + "factory/user/mchCert/v1/state",
    method: "get",
    params,
  });
}

// 厂商新增实名认证
export function factoryInsertCertApi(data) {
  return request({
    url: factoryURL + "factory/user/mchCert/v1/insert",
    method: "post",
    data,
  });
}
// 厂商修改实名认证
export function factoryUpdateCertApi(data) {
  return request({
    url: factoryURL + "factory/user/mchCert/v1/reCert",
    method: "post",
    data,
  });
}

// 厂商实名认证详情
export function factoryCertDetailApi(params) {
  return request({
    url: factoryURL + "factory/user/mchCert/v1/detail",
    method: "get",
    params,
  });
}

// 厂商确定微信已审核通过
export function factoryConfirmPassByWeixinApi(params) {
  return request({
    url: factoryURL + "factory/user/mchCert/v1/state/wechat",
    method: "get",
    params,
  });
}

// 厂商根据名称查询银行信息
export function factoryGetBankByNameApi(params) {
  return request({
    url: factoryURL + "factory/user/mchCert/v1/list/bank",
    method: "get",
    params,
  });
}

// 厂商根据名称查询银行网点信息
export function factoryGetBranchBankByNameApi(params) {
  return request({
    url: factoryURL + "factory/user/mchCert/v1/list/branch",
    method: "get",
    params,
  });
}

// 厂商查询设备列表
export function factoryGetDeviceListApi(params) {
  return request({
    url: factoryURL + "factory/device/info/v1/list/page",
    method: "get",
    params,
  });
}

// 厂商设备详情
export function factoryGetDeviceDetailApi(params) {
  return request({
    url: factoryURL + "factory/device/info/v1/detail",
    method: "get",
    params,
  });
}

// 厂商获取设备详情接口
export function getVendorDeviceDetailApi(params) {
  return request({
    url: factoryURL + "factory/device/info/v1/detail",
    method: "get",
    params,
  });
}

// 厂商获取硬件参数
export function factoryGetDeviceConfigApi(params) {
  return request({
    url: factoryURL + "factory/device/config/v1/hardware/list",
    method: "get",
    params,
  });
}

// 厂商修改设备硬件参数
export function factoryUpdateDeviceConfigApi(data) {
  return request({
    url: factoryURL + "factory/device/config/v1/hardware/update",
    method: "post",
    data,
  });
}

// 厂商启动测试
export function factoryStartTestApi(data) {
  return request({
    url: factoryURL + "factory/device/info/v1/test/shipment",
    method: "post",
    data,
  });
}

// 厂商获取设备型号
export function factoryGetDeviceProductApi(params) {
  return request({
    url: factoryURL + "factory/device/product/v1/option/list",
    method: "get",
    params,
  });
}

// 厂商更换主控
export function factoryUpdateProductApi(data) {
  return request({
    url: factoryURL + "factory/device/info/v1/product",
    method: "post",
    data,
  });
}

// 获取openId
export function factoryGetWeixinOpenIdApi(data) {
  return request({
    url: factoryURL + "factory/base/thirdauth/v1/white",
    method: "post",
    data: {
      // 固定参数
      jwtAppId: getJwtAppId(),
      thirdPlatform: "wechat",
      thirdPlatformSource: 1,
      code: data.code,
    },
  });
}

// 选择一个账号登录
export function factoryLoginByUserIdApi(data) {
  return request({
    url: factoryURL + "factory/user/auth/thirdapp/v1/thirdPlatform/auth",
    method: "post",
    data: {
      ...data,
    },
  });
}

// 登录检查绑定列表
export function factoryGetWxBindListApi(data) {
  return request({
    url: factoryURL + "factory/user/auth/thirdapp/v1/check/bind",
    method: "post",
    data: {
      ...data,
    },
  });
}

// 通过userId检查是否绑定
export function factoryGetWxBindListByUserIdApi(data) {
  return request({
    url: factoryURL + "factory/user/auth/thirdapp/v1/check/bind/userId",
    method: "post",
    data: {
      ...data,
    },
  });
}

// 绑定微信
export function factoryBindWeixinApi(data) {
  return request({
    url: factoryURL + "factory/user/auth/thirdapp/v1/bind/user",
    method: "post",
    data: {
      ...data,
    },
  });
}

// 校验登录
export function factoryVerifyLoginApi(data) {
  return request({
    url: factoryURL + "factory/user/auth/thirdapp/v1/login/verify",
    method: "post",
    data: {
      ...data,
    },
  });
}

// 解除绑定
export function factoryUnbindApi(data) {
  return request({
    url: factoryURL + "factory/user/auth/thirdapp/v1/unbind/user",
    method: "post",
    data,
  });
}
