// 顶部菜单栏
export const TopMenus = [
  {
    icon: "iconfont-shebeiguanli",
    name: "设备管理",
    path: "/device/operator",
    key: "deviceList",
    bg: "#1890FF",
    auth: "B0101,B010101",
  },
  {
    icon: "iconfont-shebeibangding",
    name: "设备绑定",
    path: "/device/register/scan",
    key: "deviceBind",
    bg: "#F2AE42",
    auth: "B0102",
  },
  {
    icon: "iconfont-zhifudingdan",
    name: "支付订单",
    path: "/order/list",
    key: "orderList",
    bg: "#09C099",
    auth: "B0201,B020101",
  },
  {
    icon: "iconfont-shangpintaocan",
    name: "商品套餐",
    path: "/package/list",
    key: "packageList",
    bg: "#F1594C",
    auth: "B0301",
  },
];

// 功能菜单栏
export const BottomMenus = [
  {
    icon: "iconfont-toubijilu",
    name: "投币记录",
    path: "/device/record/coin-record",
    key: "startRecord",
    bg: "#1890FF",
    auth: "B0203,B020301",
  },
  {
    icon: "iconfont-shangpinguanli",
    name: "商品管理",
    path: "/goods/list",
    key: "goodsList",
    bg: "#F2AE42",
    auth: "B0302",
  },
  {
    icon: "iconfont-toubihuizong",
    name: "投币汇总",
    path: "/device/record/coin-summary",
    key: "coinRecord",
    bg: "#09C099",
    auth: "B0203,B020301",
  },
  {
    icon: "iconfont-lipinjilu",
    name: "礼品记录",
    path: "/device/record/gift-record",
    key: "giftRecord",
    bg: "#F1594C",
    auth: "B0204,B020401",
  },
  {
    icon: "iconfont-shouruhuizong",
    name: "收入汇总",
    path: "/record/income",
    key: "income",
    bg: "#F2AE42",
    auth: "B0205",
  },
  {
    icon: "iconfont-tuikuanjilu",
    name: "退款记录",
    path: "/refund/list",
    key: "refund",
    bg: "#09C099",
    auth: "",
  },
  {
    icon: "iconfont-shimingrenzheng",
    name: "实名认证",
    path: "/user/certification",
    key: "vertifi",
    bg: "#09C099",
    auth: "B0403",
  },
  {
    icon: "iconfont-changdiguanli",
    name: "场地管理",
    path: "/point/index",
    key: "pointList",
    bg: "#F1594C",
    auth: "B0401",
  },
  {
    icon: "iconfont-guanggaoguanli",
    name: "广告管理",
    path: "/advertisement/list",
    key: "advertisementList",
    bg: "#1890FF",
    auth: "B0601",
  },
  {
    icon: "iconfont-huiyuanguanli",
    name: "会员管理",
    path: "/member/list",
    key: "memberList",
    bg: "#F2AE42",
    auth: "B0501",
  },
  {
    icon: "iconfont-tongzhipeizhi",
    name: "通知配置",
    path: "/notice/index",
    key: "notice",
    bg: "#1890FF",
    auth: "",
  },
  {
    icon: "iconfont-lianxikefu",
    name: "联系客服",
    path: "",
    key: "concact",
    bg: "#F2AE42",
    auth: false, // 不校验权限
  },
  {
    icon: "iconfont-caozuoshouce",
    name: "操作手册",
    path: "",
    key: "doc",
    bg: "#1890FF",
    to: "https://support.qq.com/products/363925/faqs-more?",
    auth: false, // 不校验权限
  },
];

// 厂商菜单
export const VendorMenus = [
  {
    icon: "shebeizhuce",
    title: "设备注册",
    subTitle: "绑定设备所属厂商",
    path: "/device/register/scan",
    key: "deviceBind",
    auth: "C0102",
  },
  {
    icon: "shimingrenzheng1",
    title: "实名认证",
    subTitle: "用于收款认证",
    path: "/user/certification",
    key: "vertifi",
    auth: "C0201",
  },
  {
    icon: "shebeiguanli1",
    title: "设备管理",
    subTitle: "启动测试、设置硬件参数等操作",
    path: "/device/vendor",
    key: "deviceManage",
    auth: "",
  },
];
