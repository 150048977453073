<template>
  <div class="u-p-t-70">
    <van-loading type="spinner" color="#027AFF" vertical size="40px">
      加载中...
    </van-loading>
  </div>
</template>

<script>
import { defineComponent, ref, onActivated } from "vue";
import { useActions } from "@/hooks/useVuex";
import { useRouter } from "vue-router";
import { getQueryStringByUrl } from "@/utils/getQueryByUrl";
import { LocalStorageEnum } from "@/hooks/useLocalStorage";

export default defineComponent({
  name: "Redirect",
  setup() {
    const router = useRouter();
    const {
      getWeixinOpenIdAction,
      checkWeixinBindAccountAction,
      setInvitationCode,
    } = useActions("user", [
      "getWeixinOpenIdAction",
      "checkWeixinBindAccountAction",
      "setInvitationCode",
    ]);
    const Roles = [
      {},
      {
        role: "运营商",
        key: "Operator",
        jwtAppId: "MCH_MANAGE",
      },
      {
        role: "厂商",
        key: "Vendor",
        jwtAppId: "FACTORY_MANAGE",
      },
    ];
    const query = ref({
      r: 1, // 角色
      code: "", // 微信授权临时code
      mchId: "", // 厂商注册邀请码，如果存在，需要在注册的时候传给后端
    });
    onActivated(async () => {
      try {
        query.value = await getQueryStringByUrl();
        console.log("query.value", query.value);
        const r = query.value.r;
        localStorage.setItem(LocalStorageEnum.ROLE, Roles[r].key);
        localStorage.setItem(LocalStorageEnum.JWT_APPID, Roles[r].jwtAppId);
        if (process.env.VUE_APP_ENV !== "development") {
          await getWeixinOpenIdAction({
            code: query.value.code,
          });
        }
        // 如果存在邀请码，就直接去登录页面，让用户自己注册
        if (query.value.mchId) {
          setInvitationCode(query.value.mchId);
          router.replace("/login");
          return false;
        }
        await checkWeixinBindAccountAction();
      } catch (error) {
        console.log("error", error);
      }
    });
    return {};
  },
});
</script>

<style lang="scss" scoped></style>
