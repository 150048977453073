import Home from "@/views/home/index.vue";
import Redirect from "@/views/redirect.vue";
import advertisement from "./advertisement";
import device from "./device";
import income from "./income";
import member from "./member";
import other from "./other";
import refund from "./refund";
import user from "./user";
export default {
  route: [
    {
      path: "/home/index",
      name: "Home",
      meta: {
        title: "首页",
      },
      component: Home,
    },
    {
      path: "/redirect",
      name: "Redirect",
      meta: {
        title: "正在跳转...",
      },
      component: Redirect,
    },
    ...device,
    ...user,
    ...other,
    ...income,
    ...member,
    ...advertisement,
    ...refund,
    {
      path: "/:catchAll(.*)*",
      redirect: "/home/index",
    },
  ],
};
