export function initDirective(app) {
  // vue3 自定义指令修改了生命周期钩子
  // bind => beforeMount
  // inserted => mounted
  // beforeUpdate: 新的钩子，会在元素自身更新前触发
  // update => 移除！
  // componentUpdated => updated
  // beforeUnmount: 新的钩子，当元素自身被卸载前触发
  // unbind => unmounted
  // 2位小数
  app.directive("numberPrice", {
    mounted: function (el, binding) {
      console.log(el, binding);
      el.onkeyup = function () {
        el.value = el.value.match(/^\d*(\.?\d{0,2})/g)[0] || null;
        trigger(el, "input");
      };
    },
    unmounted: function (el) {
      console.log(el);
    },
  });
  // 限制输入正整数
  app.directive("limitInt", {
    mounted(el) {
      el.onkeyup = function () {
        if (el.value.length === 1) {
          el.value = el.value.replace(/[^1-9]/g, "");
        } else {
          el.value = el.value.replace(/[^\d]/g, "");
        }
        trigger(el, "input");
      };
      el.onblur = function () {
        if (el.value.length === 1) {
          el.value = el.value.replace(/[^1-9]/g, "");
        } else {
          el.value = el.value.replace(/[^\d]/g, "");
        }
        trigger(el, "input");
      };
    },
    unmounted() {},
  });
}

function trigger(el, type) {
  const e = document.createEvent("HTMLEvents");
  e.initEvent(type, true, true);
  el.dispatchEvent(e);
}
