import { LocalStorageEnum } from "@/hooks/useLocalStorage";
import { createRouter, createWebHistory } from "vue-router";
import NavConfig from "./config";

const router = createRouter({
  history: createWebHistory(),
  routes: NavConfig.route,
});
router.beforeEach((to, form, next) => {
  console.log("跳转之前", to, form);
  // 登录鉴权
  if (to.meta.check) {
    const casToken = localStorage.getItem(LocalStorageEnum.CAS_TOKEN) || "";
    if (casToken) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});
export default router;
