<template>
  <div class="page-content" :style="{ backgroundColor: bg ? bg : '#F5F5F5' }">
    <div v-if="isHeader" class="page-header content u-flex u-row-between">
      <div class="header-left u-flex">
        <div v-if="isBack" class="u-m-r-10" @click="onClickBack">
          <van-icon name="arrow-left" size="20" />
        </div>
        <div v-if="isHome" @click="onClickBackHome">
          <van-icon name="wap-home-o" size="20" />
        </div>
      </div>
      <div class="header-center u-text-center">{{ title || routeTitle }}</div>
      <div class="header-right u-flex u-row-right">
        <slot name="right" />
      </div>
    </div>
    <div ref="PageWrap" class="page-wrap" :class="{ 'u-m-t-44': isHeader }">
      <slot />
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  ref,
  onMounted,
  onActivated,
  onDeactivated,
  watch,
} from "vue";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  name: "Page",
  props: {
    bg: {
      type: String,
      default: "#F5F5F5",
    },
    title: {
      type: String,
      default: "",
    },
    isHeader: {
      type: Boolean,
      default: false,
    },
    isBack: {
      type: Boolean,
      default: true,
    },
    isHome: {
      type: Boolean,
      default: true,
    },
    scroll: {
      type: Number,
      default: 0,
    },
    scrollBehavior: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["scroll"],
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const PageWrap = ref(null);
    console.log("当前路由", route.meta);
    onMounted(() => {});
    onActivated(() => {
      changeTop();
      props.scrollBehavior &&
        PageWrap.value.addEventListener("scroll", onScroll);
    });
    onDeactivated(() => {
      props.scrollBehavior &&
        PageWrap.value.removeEventListener("scroll", onScroll);
    });
    const changeTop = () => {
      PageWrap.value.scrollTop = props.scroll;
    };
    watch(
      () => props.scroll,
      () => {
        changeTop();
      }
    );
    const onScroll = (e) => {
      const top = e.srcElement.scrollTop;
      // console.log("top", top);
      emit("scroll", top);
    };
    const routeTitle = computed(() => {
      return route.meta.title;
    });
    const onClickBack = () => {
      router.back();
      console.log("back");
    };
    const onClickBackHome = () => {
      router.replace({
        name: "Home",
      });
    };
    return {
      PageWrap,
      routeTitle,
      onClickBack,
      onClickBackHome,
    };
  },
});
</script>

<style lang="scss" scoped>
.page-content {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .page-header {
    position: fixed;
    width: 100%;
    height: 44px;
    background-color: var(--van-blue);
    color: #ffffff;
    z-index: 2;
    .header-left,
    .header-center,
    .header-right {
      width: 33.3%;
    }
    .header-center {
      font-weight: 500;
    }
  }
  .page-wrap {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: inherit;
    -webkit-overflow-scrolling: touch;
  }
}
</style>
