<template>
  <van-dialog
    :show="visible"
    :show-confirm-button="false"
    teleport="body"
    lock-scroll
    close-on-click-overlay
    @close="close"
  >
    <van-image width="100%" :src="ImagePrefix + 'vending/pic/h5B/kefu.png'" />
  </van-dialog>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "ConcatUs",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  inject: ["ImagePrefix"],
  emits: ["update:visible"],
  setup(props, { emit }) {
    const close = () => {
      emit("update:visible", false);
    };
    return {
      close,
    };
  },
});
</script>

<style lang="scss" scoped></style>
