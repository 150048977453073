import { getOrderDetailApi, getRefundOrderDetailApi } from "@/api/order";
export default {
  namespaced: true,
  state: {
    orderDetail: {},
    refundOrderDetail: {},
  },
  mutations: {
    SET_ORDER_DETAIL(state, payload) {
      state.orderDetail = payload;
    },
    SET_REFUND_ORDER_DETAIL(state, payload) {
      state.refundOrderDetail = payload;
    },
  },
  actions: {
    async getMchOrderDetailAction({ commit }, payload) {
      try {
        const { code, content } = await getOrderDetailApi({
          tradeNo: payload.tradeNo,
        });
        if (code === 200) {
          commit("SET_ORDER_DETAIL", content);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async getMchRefundOrderDetailAction({ commit }, payload) {
      try {
        const { code, content } = await getRefundOrderDetailApi({
          id: payload.id,
        });
        if (code === 200) {
          commit("SET_REFUND_ORDER_DETAIL", content);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  },
  getters: {
    orderDetail(state) {
      return state.orderDetail;
    },
    refundOrderDetail(state) {
      return state.refundOrderDetail;
    },
  },
};
